<template>
  <b-card>
    <div class="d-flex justify-content-between flex-wrap my-1">
      <h4>{{ $t("backoffice.settings.dates") }}</h4>
      <b-button variant="primary" class="ml-1" @click="onSubmit()">
        {{ $t("form.actions.save") }}
      </b-button>
    </div>
    <b-row class="d-flex flex-wrap">
      <!-- TimeZone -->
      <b-col cols="12">
        <b-form-group>
          <label for="time-zone" class="font-weight-bold">
            {{ $t("backoffice.settings.time-zone") }}</label
          >
          <v-select
            id="time-zone"
            v-model="form.timeZone"
            searchable
            :placeholder="$t('form.select-placeholder')"
            :options="timeZoneOptions"
            :clearable="false"
          />
        </b-form-group>
      </b-col>
      <!-- StartDate -->
      <b-col cols="12" md="6">
        <b-form-group>
          <label for="startDate" class="font-weight-bold">
            {{ $t("backoffice.settings.start-date") }}
          </label>
          <VueCtkDateTimePicker
            id="startDate"
            v-model="form.startDate"
            :locale="locale"
            format="YYYY-MM-DD HH:mm"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            no-button-now
            button-color="var(--primary)"
            class="date-picker"
          />
        </b-form-group>
      </b-col>
      <!-- EndDate -->
      <b-col cols="12" md="6">
        <b-form-group>
          <label for="endDate" class="font-weight-bold">
            {{ $t("backoffice.settings.end-date") }}
          </label>
          <VueCtkDateTimePicker
            id="endDate"
            v-model="form.endDate"
            :locale="locale"
            :behaviour="{
              time: {
                nearestIfDisabled: false,
              },
            }"
            format="YYYY-MM-DD HH:mm"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            no-button-now
            button-color="var(--primary)"
            :min-date="form.startDate"
          />
        </b-form-group>
      </b-col>
      <!-- OpeningDate -->
      <b-col cols="12" md="6">
        <b-form-group>
          <label for="openingDate" class="font-weight-bold">
            {{ $t("backoffice.settings.opening-date") }}
          </label>
          <VueCtkDateTimePicker
            id="openingDate"
            v-model="form.openingDate"
            format="YYYY-MM-DD HH:mm"
            :locale="locale"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            no-button-now
            button-color="var(--primary)"
            class="date-picker"
          />
        </b-form-group>
      </b-col>
      <!-- ClosingDate -->
      <b-col cols="12" md="6">
        <b-form-group>
          <label for="closingDate" class="font-weight-bold">
            {{ $t("backoffice.settings.closing-date") }}
          </label>
          <VueCtkDateTimePicker
            id="closingDate"
            v-model="form.closingDate"
            :locale="locale"
            format="YYYY-MM-DD HH:mm"
            :behaviour="{
              time: {
                nearestIfDisabled: false,
              },
            }"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            no-button-now
            button-color="var(--primary)"
            :min-date="form.openingDate"
          />
        </b-form-group>
      </b-col>
      <!-- Register opening Date -->
      <b-col cols="12" md="6">
        <b-form-group>
          <label for="startRegisterDate" class="font-weight-bold">
            {{ $t("backoffice.settings.register-date") }}
          </label>
          <VueCtkDateTimePicker
            id="startRegisterDate"
            v-model="form.registrationStartDate"
            :locale="locale"
            format="YYYY-MM-DD HH:mm"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            no-button-now
            button-color="var(--primary)"
            class="date-picker"
          />
        </b-form-group>
      </b-col>

      <!-- Register closing Date -->
      <b-col cols="12" md="6">
        <b-form-group>
          <label for="endRegisterDate" class="font-weight-bold">
            {{ $t("backoffice.settings.register-end-date") }}
          </label>
          <VueCtkDateTimePicker
            id="endRegisterDate"
            v-model="form.registrationEndDate"
            :locale="locale"
            format="YYYY-MM-DD HH:mm"
            :behaviour="{
              time: {
                nearestIfDisabled: false,
              },
            }"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            no-button-now
            button-color="var(--primary)"
            :min-date="form.registrationStartDate"
          />
        </b-form-group>
      </b-col>

      <!-- Draft start date -->
      <b-col cols="12" md="6">
        <b-form-group>
          <label for="publishedStartDate" class="font-weight-bold">
            {{ $t("backoffice.settings.published-start-date") }}
          </label>
          <VueCtkDateTimePicker
            id="publishedStartDate"
            v-model="form.publishedStartDate"
            :locale="locale"
            format="YYYY-MM-DD HH:mm"
            :behaviour="{
              time: {
                nearestIfDisabled: false,
              },
            }"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            no-button-now
            button-color="var(--primary)"
            :min-date="form.publishedStartDate"
          />
        </b-form-group>
      </b-col>

      <!-- Draft end date -->
      <b-col cols="12" md="6">
        <b-form-group>
          <label for="publishedEndDate" class="font-weight-bold">
            {{ $t("backoffice.settings.published-end-date") }}
          </label>
          <VueCtkDateTimePicker
            id="publishedEndDate"
            v-model="form.publishedEndDate"
            :locale="locale"
            format="YYYY-MM-DD HH:mm"
            :behaviour="{
              time: {
                nearestIfDisabled: false,
              },
            }"
            :label="$t('datepicker.select-label')"
            color="var(--primary)"
            no-button-now
            button-color="var(--primary)"
            :min-date="form.publishedEndDate"
          />
        </b-form-group>
      </b-col>

      <!-- Danger alerts messages -->
      <b-col cols="12">
        <b-alert
          v-if="
            form.startDate &&
            form.endDate &&
            unixTimestamp(form.startDate) > unixTimestamp(form.endDate)
          "
          show
          variant="danger"
          class="error-notification my-50"
        >
          {{ $t("datepicker.error-message") }}
        </b-alert>
        <b-alert
          v-if="
            form.openingDate &&
            form.closingDate &&
            unixTimestamp(form.openingDate) > unixTimestamp(form.closingDate)
          "
          show
          variant="danger"
          class="error-notification my-50"
        >
          {{ $t("datepicker.error-message-closing") }}
        </b-alert>
        <b-alert
          v-if="
            form.registrationStartDate &&
            form.registrationEndDate &&
            unixTimestamp(form.registrationStartDate) >
              unixTimestamp(form.registrationEndDate)
          "
          show
          variant="danger"
          class="error-notification my-50"
        >
          {{ $t("datepicker.error-message-closing") }}
        </b-alert>
        <b-alert
          v-if="
            form.startDraftDate &&
            form.endDraftDate &&
            unixTimestamp(form.startDraftDate) > unixTimestamp(form.endDraftDate)
          "
          show
          variant="danger"
          class="error-notification my-50"
        >
          {{ $t("datepicker.error-message-closing") }}
        </b-alert>
      </b-col>

      <!-- Notes for attention -->
      <div class="ml-1">
        <p class="mt-1 mb-25 small d-flex align-items-center">
          <feather-icon class="mr-50 text-danger" icon="AlertTriangleIcon" />
          <span class="font-italic">{{ $t("backoffice.settings.note") }}</span>
        </p>
        <p class="mb-0 small d-flex align-items-center">
          <feather-icon class="mr-50 text-danger" icon="AlertTriangleIcon" />
          <span class="font-italic">
            {{ $t("backoffice.settings.note-user") }}
          </span>
        </p>
      </div>
    </b-row>
  </b-card>
</template>

<script>
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import vSelect from "vue-select";
import { DateTime } from "luxon";
// import moment from "moment-timezone";

export default {
  name: "Dates",
  components: {
    vSelect,
  },
  mixins: [ToastNotificationsMixin],
  data() {
    return {
      form: {
        startDate: "",
        endDate: "",
        openingDate: "",
        closingDate: "",
        registrationStartDate: "",
        registrationEndDate: "",
        publishedStartDate: "",
        publishedEndDate: "",
        timeZone: "",
      },
      timeZoneOptionsFull: {},
      timeZoneOptions: [],
    };
  },
  computed: {
    currentCollective() {
      return this.$store.getters.currentCollective;
    },
    locale() {
      return this.$store.getters.currentLocale;
    },
  },
  watch: {
    "form.timeZone": function (newValue) {
      this.form.startDate = this.form?.startDate
        ? DateTime.fromISO(this.form.startDate, { zone: newValue }).toString()
        : null;
      this.form.endDate = this.form?.endDate
        ? DateTime.fromISO(this.form.endDate, { zone: newValue }).toString()
        : null;
      this.form.openingDate = this.form?.openingDate
        ? DateTime.fromISO(this.form.openingDate, { zone: newValue }).toString()
        : null;
      this.form.closingDate = this.form?.closingDate
        ? DateTime.fromISO(this.form.closingDate, { zone: newValue }).toString()
        : null;
      this.form.registrationStartDate = this.form?.registrationStartDate
        ? DateTime.fromISO(this.form.registrationStartDate, {
            zone: newValue,
          }).toString()
        : null;
      this.form.registrationEndDate = this.form?.registrationEndDate
        ? DateTime.fromISO(this.form.registrationEndDate, {
            zone: newValue,
          }).toString()
        : null;
      this.form.publishedStartDate = this.form?.publishedStartDate
        ? DateTime.fromISO(this.form.publishedStartDate, {
            zone: newValue,
          }).toString()
        : null;
      this.form.publishedEndDate = this.form?.publishedEndDate
        ? DateTime.fromISO(this.form.publishedEndDate, {
            zone: newValue,
          }).toString()
        : null;
    },
  },
  async created() {
    await this.getTimezones();
    this.form.timeZone = this.currentCollective.timezone;
    this.form.startDate = this.currentCollective?.startDate
      ? DateTime.fromISO(this.currentCollective.startDate, {
          zone: this.form.timeZone,
        }).toString()
      : null;
    this.form.endDate = this.currentCollective?.endDate
      ? DateTime.fromISO(this.currentCollective.endDate, {
          zone: this.form.timeZone,
        }).toString()
      : null;
    this.form.openingDate = this.currentCollective?.openingDate
      ? DateTime.fromISO(this.currentCollective.openingDate, {
          zone: this.form.timeZone,
        }).toString()
      : null;
    this.form.closingDate = this.currentCollective?.closingDate
      ? DateTime.fromISO(this.currentCollective.closingDate, {
          zone: this.form.timeZone,
        }).toString()
      : null;
    this.form.registrationStartDate = this.currentCollective
      ?.registrationStartDate
      ? DateTime.fromISO(this.currentCollective.registrationStartDate, {
          zone: this.form.timeZone,
        }).toString()
      : null;
    this.form.registrationEndDate = this.currentCollective?.registrationEndDate
      ? DateTime.fromISO(this.currentCollective.registrationEndDate, {
          zone: this.form.timeZone,
        }).toString()
      : null;
    this.form.publishedStartDate = this.currentCollective?.publishedStartDate
      ? DateTime.fromISO(this.currentCollective.publishedStartDate, {
          zone: this.form.timeZone,
        }).toString()
      : null;
    this.form.publishedEndDate = this.currentCollective?.publishedEndDate
      ? DateTime.fromISO(this.currentCollective.publishedEndDate, {
          zone: this.form.timeZone,
        }).toString()
      : null;
  },
  methods: {
    unixTimestamp(date) {
      const newDate = new Date(date);
      return Math.floor(newDate.getTime() / 1000);
    },
    async onSubmit() {
      const settings = {
        timezone: parseInt(
          Object.entries(this.timeZoneOptionsFull).find(
            (item) => item[1] === this.form.timeZone
          )?.[0],
          10
        ),
      };
      const dates = [
        "startDate",
        "endDate",
        "openingDate",
        "closingDate",
        "registrationStartDate",
        "registrationEndDate",
        "publishedStartDate",
        "publishedEndDate",
      ];
      for (const date of dates) {
        const nameDate = this.form[date]
          ? { name: date, value: this.timeToUTC(this.form[date]) }
          : { name: `${date}Deleted`, value: true };
        settings[nameDate.name] = nameDate.value;
      }

      try {
        const response = await this.$store.dispatch("editCommunity", {
          settings,
        });
        this.notifySuccess(this.$t("backoffice.settings.messages.success"));
        return response.data;
      } catch {
        this.notifyError(this.$t("backoffice.settings.messages.error"));
      }
    },
    timestampToTime(timestamp) {
      this.$moment.locale(this.currentLocale);
      return this.$moment(timestamp).format("DD MMM YYYY - HH:mm");
    },
    timeToUTC(date) {
      const dateNotChanged = date.indexOf("T") >= 0;
      if (dateNotChanged) {
        return date;
      }
      // 1. Primero convertimos la fecha a Iso, pero se pone con el timezone del usuario
      const dateISOUserTimezone = DateTime.fromISO(
        new Date(date).toISOString()
      );
      const dateUserTimezoneToString = dateISOUserTimezone.toString();
      // Tenemos la fecha en string, cortamos el timezone del usuario (es el último trozo del String, empieza con + o -):
      let positionUserTimezone = dateUserTimezoneToString.lastIndexOf("+");
      if (positionUserTimezone < 0) {
        positionUserTimezone = dateUserTimezoneToString.lastIndexOf("-");
      }
      // Primera parte de la fecha:
      const firstPieceOfDate = dateUserTimezoneToString.slice(
        0,
        positionUserTimezone
      );

      // 2. Ahora necesitamos la diferencia horaria del timezone del espacio. Convertimos la fecha en su timezone (pero cambia siempre la fecha)
      const dateISOSpaceTimezone = DateTime.fromISO(
        new Date(date).toISOString(),
        {
          zone: this.form.timeZone,
        }
      );
      const dateSpaceTimezoneToString = dateISOSpaceTimezone.toString();
      // Tenemos la fecha en string, cortamos el timezone del space (es el último trozo del String, empieza con + o -):
      let positionSpaceTimezone = dateSpaceTimezoneToString.lastIndexOf("+");
      if (positionSpaceTimezone < 0) {
        positionSpaceTimezone = dateSpaceTimezoneToString.lastIndexOf("-");
      }
      // Segunda parte de la fecha:
      const lastPieceTimeOfDate = dateSpaceTimezoneToString.slice(
        positionSpaceTimezone
      );

      // 3. Juntamos las dos partes de la fecha.
      const newDate = firstPieceOfDate + lastPieceTimeOfDate;
      return newDate;
    },

    async getTimezones() {
      const response = await this.$store.dispatch("getTimezones");
      this.timeZoneOptionsFull = response;
      this.timeZoneOptions = Object.values(response);
    },
  },
};
</script>
