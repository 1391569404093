var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('div',{staticClass:"d-flex justify-content-between flex-wrap my-1"},[_c('h4',[_vm._v(_vm._s(_vm.$t("backoffice.settings.dates")))]),_c('b-button',{staticClass:"ml-1",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onSubmit()}}},[_vm._v(" "+_vm._s(_vm.$t("form.actions.save"))+" ")])],1),_c('b-row',{staticClass:"d-flex flex-wrap"},[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"time-zone"}},[_vm._v(" "+_vm._s(_vm.$t("backoffice.settings.time-zone")))]),_c('v-select',{attrs:{"id":"time-zone","searchable":"","placeholder":_vm.$t('form.select-placeholder'),"options":_vm.timeZoneOptions,"clearable":false},model:{value:(_vm.form.timeZone),callback:function ($$v) {_vm.$set(_vm.form, "timeZone", $$v)},expression:"form.timeZone"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"startDate"}},[_vm._v(" "+_vm._s(_vm.$t("backoffice.settings.start-date"))+" ")]),_c('VueCtkDateTimePicker',{staticClass:"date-picker",attrs:{"id":"startDate","locale":_vm.locale,"format":"YYYY-MM-DD HH:mm","label":_vm.$t('datepicker.select-label'),"color":"var(--primary)","no-button-now":"","button-color":"var(--primary)"},model:{value:(_vm.form.startDate),callback:function ($$v) {_vm.$set(_vm.form, "startDate", $$v)},expression:"form.startDate"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"endDate"}},[_vm._v(" "+_vm._s(_vm.$t("backoffice.settings.end-date"))+" ")]),_c('VueCtkDateTimePicker',{attrs:{"id":"endDate","locale":_vm.locale,"behaviour":{
            time: {
              nearestIfDisabled: false,
            },
          },"format":"YYYY-MM-DD HH:mm","label":_vm.$t('datepicker.select-label'),"color":"var(--primary)","no-button-now":"","button-color":"var(--primary)","min-date":_vm.form.startDate},model:{value:(_vm.form.endDate),callback:function ($$v) {_vm.$set(_vm.form, "endDate", $$v)},expression:"form.endDate"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"openingDate"}},[_vm._v(" "+_vm._s(_vm.$t("backoffice.settings.opening-date"))+" ")]),_c('VueCtkDateTimePicker',{staticClass:"date-picker",attrs:{"id":"openingDate","format":"YYYY-MM-DD HH:mm","locale":_vm.locale,"label":_vm.$t('datepicker.select-label'),"color":"var(--primary)","no-button-now":"","button-color":"var(--primary)"},model:{value:(_vm.form.openingDate),callback:function ($$v) {_vm.$set(_vm.form, "openingDate", $$v)},expression:"form.openingDate"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"closingDate"}},[_vm._v(" "+_vm._s(_vm.$t("backoffice.settings.closing-date"))+" ")]),_c('VueCtkDateTimePicker',{attrs:{"id":"closingDate","locale":_vm.locale,"format":"YYYY-MM-DD HH:mm","behaviour":{
            time: {
              nearestIfDisabled: false,
            },
          },"label":_vm.$t('datepicker.select-label'),"color":"var(--primary)","no-button-now":"","button-color":"var(--primary)","min-date":_vm.form.openingDate},model:{value:(_vm.form.closingDate),callback:function ($$v) {_vm.$set(_vm.form, "closingDate", $$v)},expression:"form.closingDate"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"startRegisterDate"}},[_vm._v(" "+_vm._s(_vm.$t("backoffice.settings.register-date"))+" ")]),_c('VueCtkDateTimePicker',{staticClass:"date-picker",attrs:{"id":"startRegisterDate","locale":_vm.locale,"format":"YYYY-MM-DD HH:mm","label":_vm.$t('datepicker.select-label'),"color":"var(--primary)","no-button-now":"","button-color":"var(--primary)"},model:{value:(_vm.form.registrationStartDate),callback:function ($$v) {_vm.$set(_vm.form, "registrationStartDate", $$v)},expression:"form.registrationStartDate"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"endRegisterDate"}},[_vm._v(" "+_vm._s(_vm.$t("backoffice.settings.register-end-date"))+" ")]),_c('VueCtkDateTimePicker',{attrs:{"id":"endRegisterDate","locale":_vm.locale,"format":"YYYY-MM-DD HH:mm","behaviour":{
            time: {
              nearestIfDisabled: false,
            },
          },"label":_vm.$t('datepicker.select-label'),"color":"var(--primary)","no-button-now":"","button-color":"var(--primary)","min-date":_vm.form.registrationStartDate},model:{value:(_vm.form.registrationEndDate),callback:function ($$v) {_vm.$set(_vm.form, "registrationEndDate", $$v)},expression:"form.registrationEndDate"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"publishedStartDate"}},[_vm._v(" "+_vm._s(_vm.$t("backoffice.settings.published-start-date"))+" ")]),_c('VueCtkDateTimePicker',{attrs:{"id":"publishedStartDate","locale":_vm.locale,"format":"YYYY-MM-DD HH:mm","behaviour":{
            time: {
              nearestIfDisabled: false,
            },
          },"label":_vm.$t('datepicker.select-label'),"color":"var(--primary)","no-button-now":"","button-color":"var(--primary)","min-date":_vm.form.publishedStartDate},model:{value:(_vm.form.publishedStartDate),callback:function ($$v) {_vm.$set(_vm.form, "publishedStartDate", $$v)},expression:"form.publishedStartDate"}})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('b-form-group',[_c('label',{staticClass:"font-weight-bold",attrs:{"for":"publishedEndDate"}},[_vm._v(" "+_vm._s(_vm.$t("backoffice.settings.published-end-date"))+" ")]),_c('VueCtkDateTimePicker',{attrs:{"id":"publishedEndDate","locale":_vm.locale,"format":"YYYY-MM-DD HH:mm","behaviour":{
            time: {
              nearestIfDisabled: false,
            },
          },"label":_vm.$t('datepicker.select-label'),"color":"var(--primary)","no-button-now":"","button-color":"var(--primary)","min-date":_vm.form.publishedEndDate},model:{value:(_vm.form.publishedEndDate),callback:function ($$v) {_vm.$set(_vm.form, "publishedEndDate", $$v)},expression:"form.publishedEndDate"}})],1)],1),_c('b-col',{attrs:{"cols":"12"}},[(
          _vm.form.startDate &&
          _vm.form.endDate &&
          _vm.unixTimestamp(_vm.form.startDate) > _vm.unixTimestamp(_vm.form.endDate)
        )?_c('b-alert',{staticClass:"error-notification my-50",attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t("datepicker.error-message"))+" ")]):_vm._e(),(
          _vm.form.openingDate &&
          _vm.form.closingDate &&
          _vm.unixTimestamp(_vm.form.openingDate) > _vm.unixTimestamp(_vm.form.closingDate)
        )?_c('b-alert',{staticClass:"error-notification my-50",attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t("datepicker.error-message-closing"))+" ")]):_vm._e(),(
          _vm.form.registrationStartDate &&
          _vm.form.registrationEndDate &&
          _vm.unixTimestamp(_vm.form.registrationStartDate) >
            _vm.unixTimestamp(_vm.form.registrationEndDate)
        )?_c('b-alert',{staticClass:"error-notification my-50",attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t("datepicker.error-message-closing"))+" ")]):_vm._e(),(
          _vm.form.startDraftDate &&
          _vm.form.endDraftDate &&
          _vm.unixTimestamp(_vm.form.startDraftDate) > _vm.unixTimestamp(_vm.form.endDraftDate)
        )?_c('b-alert',{staticClass:"error-notification my-50",attrs:{"show":"","variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t("datepicker.error-message-closing"))+" ")]):_vm._e()],1),_c('div',{staticClass:"ml-1"},[_c('p',{staticClass:"mt-1 mb-25 small d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-50 text-danger",attrs:{"icon":"AlertTriangleIcon"}}),_c('span',{staticClass:"font-italic"},[_vm._v(_vm._s(_vm.$t("backoffice.settings.note")))])],1),_c('p',{staticClass:"mb-0 small d-flex align-items-center"},[_c('feather-icon',{staticClass:"mr-50 text-danger",attrs:{"icon":"AlertTriangleIcon"}}),_c('span',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(_vm.$t("backoffice.settings.note-user"))+" ")])],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }